.button-row {
  display: flex;
  flex-direction: row;
}

.graph-container {
  display: flex;
}

.menu {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
  flex-direction: column;
  z-index: 200;
  align-items: flex-end;
  padding: 4px;
  max-width: 40px;
}

.menu-section:last-child {
  margin-bottom: 0px;
}

.menu-section-is-open:last-child {
  margin-bottom: 0px;
}

.menu-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 2px #8b949e solid;
  background-color: #161b22;
  margin-bottom: 4px;
  cursor: pointer;
}

.preview-modal {
  position: absolute;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 12px 28px 12px 12px;
  width: 300px;
  border: 2px #8b949e solid;
  background-color: #23272c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  color: #c9d1d9;
  opacity: 85%;
}

.image-preview {
  width: 150px;
  padding: 0 12px;
  float: left;
}

.article-preview-link {
  position: absolute;
  bottom: 4px;
  right: 2px;
  cursor: pointer;
}

.menu-section-is-open {
  position: relative;
  border-radius: 8px;
  border: 2px #8b949e solid;
  background-color: #161b22;
  padding: 12px 24px 12px 12px;
  margin-bottom: 8px;
  max-width: 300px;
  min-width: 300px;
}

.menu-section-is-open.article-history {
  min-width: min-content;
  max-width: 300px;
}

.close-icon-container {
  position: absolute;
  top: 4px;
  right: 2px;
  cursor: pointer;
}

.go-to-article-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  align-items: center;
}

.control-button {
  text-decoration: none;
  color: #c9d1d9;
  background-color: #30363d;
  border: 2px #8b949e solid;
  border-radius: 8px;
  padding: 8px;
  margin-right: 12px;
  font-family: 'Georgia';
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.history-container {
  overflow: scroll;
  max-height: 300px;
}

.compact {
  max-height: 120px;
}

.history-card {
  color: #8b949e;
  background-color: #30363d;
  border: 1px rgba(240,246,252,0.1) solid;
  font-family: 'Georgia';
  font-weight: 400;
  font-size: 14px;
  border-width: 1px;
  border-radius: 4px;
  padding: 6px;
  width: fit-content;
  text-align: start;
  margin: 8px 0px;
  white-space: nowrap;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.loader {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #c9d1d9;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

/* .article-preview-iframe {
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 1000;
} */

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.error-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  padding: 12px;
  color: #c9d1d9;
  background-color: #30363d;
  border: 2px #8b949e solid;
  border-radius: 8px;
  max-width: 300px;
}

.text {
  color: #c9d1d9;
  font-family: 'Georgia';
  font-size: 14px;
  margin-right: 8px;
  margin-top: 0;
  margin-bottom: 0;
  align-self: 'center';
}

.history {
  margin-bottom: 8px;
  white-space: nowrap;
}

.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 62px;
  height: 32px;
  margin: 0;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #707070;
  transition: background-color ease 0.3s;
}

.toggle:before {
  content: "on off";
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  background: #fff;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  font: 10px/28px Georgia;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -22px;
  word-spacing: 37px;
  color: #fff;
  text-shadow: -1px -1px rgba(0,0,0,0.15);
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:checked {
  background-color: rgb(56,139,253);
}

.toggle:checked:before {
  left: 32px;
}